.error {color:red}

div.terminalSession, pre.terminalSession {
    background-color: black !important;
    color: lightgreen !important;
    border-color: black !important;
    width: 100% !important;
    height: 500px !important;
    overflow: auto !important;
    padding: 10px;
}

input.terminalSession {
    background-color: black !important;
    color: lightgreen !important;
    border-color: black !important;
    width: 100% !important;
}

input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
}

@keyframes pulse {
    0% {
        background-color: #cccccc;
    }
    25% {
        background-color: #cceecc;
    }
    50% {
        background-color: #eeeeee;
    }
    75% {
        background-color: #cceecc;
    }
    100% {
        background-color: #cccccc;
    }
}

.adam6000line {
    position:absolute;
    z-index:1000;
    animation: pulse 10s infinite;
}
